import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://dldpsgraphuat.z13.web.core.windows.net/',
  serviceEndPoints: [
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://ps-graph-test-api.azurewebsites.net/api/GetDataFromGremlin?code=esxp-get',
    },
  ],
  apiBaseUrl: 'https://ps-graph-test-api.azurewebsites.net/api/GetDataFromGremlin?code=esxp-get',
};
