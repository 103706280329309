import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SampleApplication_routes implements IAppService {

  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const psGraphScenarioRoute: IStateConfig = {
      name: 'psGraph-scenario',
      url: '/ps-graph/scenario',
      componentFramework: 'React',
      data: {
        headerName: 'PS Graph Scenario',
        breadcrumbText: 'PS Graph Scenario',
        pageTitle: 'Graph Scenario Page'
      }
    };

    const routeInfo: IRouteInfo = {
      applicationName: 'ps-graph-visualizations',
      sharedBundles: ['ps-graph-visualizations-Bundle'],
      routes: [psGraphScenarioRoute]
    }

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {

    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: 'ps-graph-visualizations-Bundle',
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`,
      ],
      sequentialLoading: true,
    };

    return [bundle];
  }
}

PartnerAppRegistrationService.registerLazyLoadedApp(SampleApplication_routes);
